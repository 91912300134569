.member_hero-bg {
    background-color: #000 !important;
    width: 100vw;
    padding: 0;
    padding-left: 0 !important;
}

.brotherhood .row {
    margin-right: 0;
    margin-left: 0;
}

.btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 4rem;
}

.hero-image img {
    width: 100%;
    height: 100%;
    aspect-ratio: 12/10;
}

.center-col {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: top;
    padding: 4rem;
}
.hero-details {
    max-width: 40rem;
}

.hero-details h2 {
    font-size: 2.6rem;
}

.hero-details p {
    font-size: 1.25rem;
}

.hero-text {
    color: white;
    padding-top: 2rem;
}
.btn-cra {
    color: white;
    text-align: center;
    background: var(--primary-color);
    font-size: .9rem;
    border: 1px solid white;
    padding: 1rem;
    width: 44%;
    justify-content: center;
    align-items: center;
}

.btn-cra:hover{
    color: black;
    background: white;
    transform: scale();
}

.btn-log {
    color: var(--primary-color);
    text-align: center;
    background: white;
    font-size: .9rem;
    border: 1px solid var(--primary-color);
    padding: 1rem;
    width: 44%;
    align-items: center;
}

.btn-log:hover {
    color: white;
    background: var(--primary-color);
    border: 1px solid white;
}

.member_section {
    padding-top: 3rem;
    padding-bottom: 4rem;
}

.hero-bg {
    padding: 0 !important;
}

.work-row {
    padding-top: 5rem;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100%;
}
.works-title {
    text-align: center;
    margin-bottom: 4rem;
}

.work-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: top;
    width: 100%;
}

.work-item p {
    max-width: 22rem;
}

.work-item img {
    max-width: 15rem;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 12/12;
}

.work-items {
    display: flex;
    flex-direction: row;
    justify-content: top;
    align-items: top;
}

@media only screen and (max-width: 1020px) {
    .work-items {
        flex-direction: column;
        gap: 5rem;
        align-items: center;
        min-height: 5rem;

    }

    .work-item p {
        width: 100%;
        align-items: start;
    }

    .work-item img {
        max-width: 10rem;
        height: auto;
    }

    .tier-row {
        gap: 6rem;
    }

    .points_method-wrapper {
        flex-direction: column !important;
        align-items: baseline;
        flex: 1;
    }

    .section4-wrapper {
        flex-direction: row !important;
        justify-content: baseline;
        align-items: start;
        height: 100%;
        align-items: start !important;
        justify-content: space-between;
    }

    .point-method .a {
        display: flex;
        flex-direction: column;
        align-items: center !important;
        justify-content: center;
        gap: .75rem;
        width: 100%;
        min-height: 13rem;
    }

    .point-method .social-url {
        align-items: center;
    }

    .social-url {
        align-items: center !important;
    }
}

@media only screen and (max-width: 768px) {
    .member_section-3 {
        padding: 2rem !important;
    }
    .hero-bg {
        display: none !important;
    }
    .point-text {
        max-width: 10rem;
        text-align: center;
        display: flex;
        height: 80px;
    }

    .center-col {
        padding: 2rem;
    }

    .center-col h2{
        font-size: xx-large;
    }

    .center-col p{
        font-size: medium;
    }

    .btn-cra {
        width: auto;
    }
}


.btn-cta {
    background: var(--primary-color);
    color: white;
    padding: .5rem;
    width: 40%;
}

.btn-cta:hover {
    background: var(--primary-hover-color);
}

.tier-image {
    max-width: 20rem;
    height: auto;
}

.benefits-heading {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
}

.benefits-text {
    font-size: 1.1rem;
    font-weight: 400;
    text-align: center;
}

.tier-row {
    display: flex;
    flex-direction: row;
    align-items: top;
    margin-top: 4rem;
    justify-content: center;
}

.member_section-3 {
    padding: 4rem;
}

.points_method-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
}
.circle {
    border-radius: 50%;
    border: #000 solid 1px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.point-method {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .75rem;
    width: 100%;
    max-width: 22rem;
}

.point-method i {
    font-size: 2.2rem;
}

.point-method p {
    max-width: 10rem;
    text-align: center;
}

.section4-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    align-items: center;
    justify-content: center;
}
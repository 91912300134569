.product-section {
    padding-top: 5rem;
    padding-bottom: 10rem;
}
.product-details {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
}
@media only screen and (max-width: 1020px) {
    .product-details {
        padding-left: 0;
    }

    .product-section {
        padding-top: 0;
        padding-bottom: 10rem;
    }
}

.product-name {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
}

.product-price {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.4;
}

.input-height {
    height: 3rem;
    padding: .5rem;
}

.quantity {
    width: 20%;
}

.size-dropdown {
    width: 100%;
}

.dropdown-toggle {
    width: 100%;
    background-color: white !important;
    color: var(--primary-color);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.dropdown-toggle:hover {
    width: 100%;
    background-color: white !important;
    color: var(--primary-color) !important;
}

.product-btn {
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    padding: .75rem 1.5rem;
    text-decoration: none;
    border: 1px solid;
    margin-top: .2rem;
    margin-bottom: .2rem;
}

.product-btn:hover {
    background-color: var(--primary-hover-color);
}

.video-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.responsive-video {
    width: 100%;
    height: auto;
    max-width: 50vw; /* 50% of the viewport width */
    max-height: 50vh; /* Adjust if needed for viewport height */
}

.quantity-input {
    margin: 0% !important;
}

/* Custom Toast Container */
.cart_custom-toast {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    background: white;
    border: 1px solid black;
    padding: 5px;
    border-radius: 8px;
    width: auto;
}

/* Green Check Mark */
.cart_toast-icon {
    color: green;
    font-size: 2rem;
    margin-right: .8rem;
    margin-left: .5rem;
}

/* Toast Message */
.cart_toast-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: none !important;
    color: var(--primary-color);
}

.cart_toast-text {
    white-space: normal; /* Allow text to wrap */
    word-wrap: break-word; /* Ensure long words break */
    overflow-wrap: break-word;
    font-size: large;
    line-height: normal;
}

/* Product Image */
.cart_toast-image {
    width: 100px;
    height: 70px;
    border-radius: 5px;
    object-fit: contain;
}

.add-to-cart-toast {
    background: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    width: auto !important;
    height: auto !important;
}
/* 
.out-of-stock-btn {
    background-color: black;
    color: white;
    padding: 6px 12px;
    border-radius: 15px;
    font-weight: bold;
    font-size: 14px;
    display: inline-block;
} */

@media only screen and (max-width: 425px) {
    .product-name {
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.2;
    }
}

@media only screen and (max-width: 768px) {
    .cart_custom-toast {
        width: 60%;
        padding: 0px;
        margin-left: auto;
    }
}
.bg-color {
    background-color: #f5f5f5 !important;
}

.row {
    justify-content: center;
}

.form-grid {
    justify-content: right !important;
    align-items: flex-end;
    display: flex;
}

.checkout {
    padding: 1rem;
}
.checkout h6 {
    font-weight: 600;
    font-size: 1.5rem;
}

.form-col {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: right;
    float: right;
}

.form-col Button {
    width: 100%;
    background-color: var(--primary-color) !important;
    margin-bottom: .7rem;
}

.form-col Button:hover {
    background-color: var(--primary-hover-color) !important;
    margin-bottom: .7rem;
}

.form-group input {
    background-color: #f5f5f5;
    border: #e6e6e6 1px solid;
    padding: .3rem .7rem;
    width: 100%;
    margin-top: .2rem;
    font-size: .9rem;
}

.form-group select {
    background-color: #f5f5f5;
    border: #e6e6e6 2px solid;
    padding: .7rem;
    padding-right: 2rem;
    width: 100%;
    margin-top: .3rem;
    font-size: .8rem;
}

.form-group label {
    margin-top: .2rem;
    font-size: .9rem;
}

.form-section {
    background-color: #fff;
    margin-bottom: 1rem;
    padding-bottom: .5rem;
    gap: 0;
}

.form-section span{
    border: #e6e6e6 1px solid;
    padding: .5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.form-section .row {
    margin-top: 0;
}

.form-group {
    padding: .3rem 1rem;
    margin-top: .2rem;
    margin-bottom: 0;
}

.form-group .radio-input{
    margin-top: .5rem;
}

.mb-3 {
    margin-bottom: 0 !important;
}

.street-input {
    margin-bottom: .3rem;
}

.radio-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
}

.radio-input input {
    width: auto;
    margin: 0;
}

.radio-input label {
    padding: 0;
    margin: 0;
}

.fee-details {
    display: flex;
    flex-direction: column;
}

.fee-details span {
    border: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.order-summary .form-section {
    padding-bottom: 0;
    padding: 0;
}

.price {
    padding-left: 0 !important;
}

.order-summary Button {
    width: 100%;
    background-color: var(--primary-color);
    padding: 1rem;
}

.checkout_cart-items-container {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adds spacing between cart items */
    margin-bottom: 1rem;
}

.checkout_product-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: white;
    border-radius: 5px;
}

.checkout_cart-desc {
    display: flex;
    gap: 5px;
    width: 100%;
}

.checkout_item-image {
    width: 4rem;  /* Fixed width */
    height: 4rem; /* Fixed height */
    object-fit: cover; /* Ensures cropping */
    border-radius: 5px; /* Optional */
    display: block;
    aspect-ratio: 1/1;
}

.checkout_cart-details .p-name {
    font-size: small;
    width: 100%;
    overflow: auto;
    line-height: normal;
}

.checkout_cart-details p {
    font-size: smaller;
    width: 100%;
    line-height: normal;
}

.checkout_input {
    width: 30%;
    font-size: small;
    text-align: end;
    margin-right: 7%;
}

.coupon-section {
    background-color: #fff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
}
.coupon-card {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .checkout {
        padding: 0;
        padding-top: 1rem;
    }

    select {
        appearance: none; /* Removes default styling */
        -webkit-appearance: none; /* Removes Safari styling */
        -moz-appearance: none; /* Removes Firefox styling */
        color: var(--primary-color);
        font-size: .9rem !important;
    }
}
.profile-header {
    background: var(--primary-color);
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem;
}

.profile-header-width {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1440px;
    align-items: center;
}

.profile-header h2 {
    color: white;
    max-width: 20rem;
    text-align: center;
}

.profile-header span {
    color: white;
    max-width: 20rem;
    text-align: center;
}

.level-info {
    display: flex;
    flex-direction: row;
}

.level-info img {
    max-width: 10rem;
}

.level-details {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
}

.profile-bio h3 {
    padding: 1rem;
    text-align: right;
    width: 100%;
}

.profile_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile-bio {
    margin: 0 !important;
    justify-content: start;
    display: flex;
    max-width: 1440px;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .profile-header-width {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        max-width: 1440px;
        align-items: start;
        gap: 2rem;
    }

    .profile-header h2 {
        max-width: none;
        text-align: start;
        font-size: x-large;
    }

    .profile-bio h3 {
        text-align: start;
        display: none;
    }

    .profile-bio .col-md-4.col-lg-3 {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
}
.modal-body {
    padding: 0;
}

.modal {
    --bs-modal-margin: 0;
}

.modal-content {
    margin-top: 25%;
}

.modal_content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    padding: 1rem;
    padding-top: 2rem;
    text-align: center;
    padding-bottom: 2rem;
}

.modal_content-wrapper h2 {
    font-weight: 300 !important;
    font-size: 6rem;
}

.modal_content-wrapper p {
    font-size: 1rem;
}
.modal_content-wrapper img {
    max-width: 15rem;
}

.modal-image {
    height: 100%;
}

.modal-btn {
    background-color: var(--primary-color);
    color: var(--nav-color);
    padding: .75em;
    width: 75%;
}

.modal-btn:hover {
    color: var(--nav-color);
}

.modal-dialog-centered {
    max-width: 50rem !important;
    width: 100%;
    display: flex;
    justify-content: center;
}

.image-container {
    display: row;
    flex-direction: column;
}

.image-container .tier-image{
    width: 25%;
}

.image-container-mobile {
    display: none;
}

.close {
    display: flex;
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: -2%;
    top: -3%;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.close p {
    font-size: 1.5rem;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .modal-image {
        display: none;
    }

    .close {
        display: flex;
        position: absolute;
        width: 2rem;
        height: 2rem;
        right: -5%;
        top: -3%;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        background-color: black;
    }

    .close p {
        font-size: 1rem;
        align-items: center;
        color: white;
    }

    .modal_content-wrapper {
        padding: 1rem;
        padding-top: 0;
        text-align: center;
        padding-bottom: 1rem;
        row-gap: 0;
    }

    .modal_content-wrapper p {
        font-size: .8rem;
        max-width: 85%;
        margin-bottom: .8rem;
    }

    .modal_content-wrapper h2 {
        font-weight: 300 !important;
        font-size: 4rem;
    }

    .modal-btn {
        margin-top: 1.5rem;
        width: 75%;
    }

    .image-container {
        display: none;
        flex-direction: column;
    }

    .image-container-mobile {
        display: flex;
        flex-direction: row;
        gap: 1%;
        max-width: 100%;
        justify-content: center;
    }

    .image-container-mobile .tier-image-mobile {
        width: 29%;
    }

    .modal-content {
        margin-top: 50%;
    }
}
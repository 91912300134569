.p-policy {
    margin-bottom: 13rem;
}

.p-policy h1 {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.p-policy h2 {
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    font-size: larger;
    font-weight: 700;
}

.p-policy p {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    line-height: 2rem;
}

.p-policy a {
    color: blue !important;
    cursor: pointer;
}


.inline-heading {
    font-size: large;
    font-weight: 600;
}

.custom-list {
  list-style-type: none; /* Remove default bullets */
}

.custom-list li {
  position: relative;
  padding-left: 20px; /* Space for custom bullet */
  padding-bottom: 1em;
  line-height: 1.5rem;
}

.custom-list li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.7em;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: black; /* Black color for the custom bullet */
}

.category-table {
    width: 100%;
    line-height: 2rem;
}

.category-table th, .category-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

/* Center text in the third column */
.category-table td:nth-child(3), .category-table th:nth-child(3) {
    text-align: center;
}

@media only screen and (max-width: 768px) {
    ol, ul {
        padding-left: 0rem !important;
    }
}

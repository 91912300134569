/* Helvetica Neue fonts */
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55?styles=30125,16015,16016,16018,16008,16020,16022,16009,15981,15983,15985,15986,15987,16012,15991,15992,15994,15996,30128,15999,30123,30130,30135,15997');

/*  css variables */
:root {
    --card-bg-01: #fdefe6;
    --card-bg-02: #d6e5fb;
    --card-bg-03: #ceebe9;
    --card-bg-04: #e2f2b2;
    --primary-color: #000;
    --primary-hover-color: #524f4f;
    --text-color-primary-color: #000;
    --nav-color: #fff;
    --hero-bg: #d6e5fb;
    --heading-fontSize: 2rem;
    --small-text-color: #999;
    --heading-text-color: #0a1d37;
    --footer-bg: #031a2d;
}

/* reset browser default */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Bodoni Moda', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; 
    display: flex;
    flex-direction: column;       
    min-height: 100vh;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--text-color-primary-color);
}

/* @font-face {
    font-family: 'Bodoni';
    font-style: normal;
    font-weight: normal;
    src: local('Bodoni'), url('../src/assets/fonts/bodoni-webfont/bodoni_[allfont.net].woff') format('woff');
} */

h1,
h2,
h3,
h4,
h5,
h6,
p {
    padding: 0;
    margin: 0;
}

p {
    font-size: 1rem;
}

h1,
h2 {
    font-size: var(--heading-fontSize);
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: unset;
}

img {
    width: 100%;
}

button {
    cursor: pointer;
    background-color: transparent;
}

button[type="button"] {
    background-color: transparent !important;
}

section {
    padding: 60 px 0px;
}
.login-form {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.login-form h3{
    font-weight: 500;
    font-size: 4rem;
    margin-bottom: 3rem;
    border-radius: 5px;
}

.login-form p{
    margin-top: 3rem;
    margin-bottom: 3rem;
    text-align: center;
}

.auth-form .signup{
    margin-top: -.5rem;
    text-align: center;
}

.signup .signup-link{
    border-bottom: solid 2px var(--primary-color);
    height: 1.5rem;
    font-weight: bold;
}

.forgot-password:hover{
    color: #000;
}

.signup .signup-link:hover{
    color: #000;
}

.auth-form input {
    width: 100%;
    height: 3rem;
    padding: 1rem;
}

.login-input input{
    margin-bottom: 2rem !important;
}

.btn-login {
    width: 100%;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
}

.auth_btn-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.auth_btn-wrapper Button {
    width: 30%;
    padding: 1rem;
}

.auth_btn-wrapper p {
    border-bottom: solid 2px var(--primary-color);
    height: 1.5rem;
    font-weight: bold;
}

@media only screen and (max-width: 1020px) {
    .auth-form input {
        padding: 1rem;
        margin-top: 1rem;
        margin-bottom: 1em;
    }
}
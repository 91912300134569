.product-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-bottom: 1rem;
}

.product-image-container {
    height: auto;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-image {
    aspect-ratio: 10/12;
    object-fit: cover;
}

.product-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 0%;
}

.product-info h3{
    font-size: 0.9rem;
    font-weight: 400;
    text-align: left;
    color: var(--primary-color);
    margin-top: .5rem;
}

.product-info a:hover{
    color: inherit;
}
.product-info span{
    font-size: .9rem;
}

.product_card-bottom {
    margin-bottom: 4rem;
}

.product_card-bottom .price {
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    width: 150vw;
}

.product_card-bottom span i {
    font-size: 1.1rem;
    background: var(--primary-color);
    padding: .1rem;
    color: white;
    border-radius: 100%;
    position: relative;
}

/* Custom Toast Container */
.cart_custom-toast {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    background: white;
    border: 1px solid black;
    padding: 5px;
    border-radius: 8px;
    width: auto;
}

/* Green Check Mark */
.cart_toast-icon {
    color: green;
    font-size: 2rem;
    margin-right: .8rem;
    margin-left: .5rem;
}

/* Toast Message */
.cart_toast-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: none !important;
    color: var(--primary-color);
}

.cart_toast-text {
    white-space: normal; /* Allow text to wrap */
    word-wrap: break-word; /* Ensure long words break */
    overflow-wrap: break-word;
    font-size: large;
    line-height: normal;
}

/* Product Image */
.cart_toast-image {
    width: 100px;
    height: 70px;
    border-radius: 5px;
    object-fit: cover;
}

.add-to-cart-toast {
    background: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    width: auto !important;
    height: auto !important;
}

.out-of-stock-btn {
    background-color: black;
    color: white;
    font-size: 8px !important;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 20px;
    margin-left: 10px;
    display: inline-block;
}

.out-of-stock-toast {
    background-color: white !important;
    color: #ff4d4d !important;
    font-weight: bold;
    border: 1px solid #ff4d4d;
}


@media only screen and (max-width: 768px) {
    .product-image-container {
        height: auto;
    }
    .product_card-bottom {
        margin-bottom: 1rem;
    }
    .product_card-bottom .price {
        font-size: 0.9rem;
    }
    .product-info h3{
        font-size: 0.8rem;
    }
    .cart_custom-toast {
        width: 60%;
        padding: 0px;
        margin-left: auto;
    }
    .product-name {
        max-width: 7rem;
    }
}
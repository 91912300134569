.cart_product-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: start;
    justify-content:space-between;
    margin-bottom: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.cart_product-wrapper input {
    text-align: center;
    justify-content: end;
    align-items: flex-end;
}

.cart_product-wrapper h2 {
    font-weight: 700;
    font-size: 1rem;
}

.cart_product-wrapper img {
    width: 6rem;
    height: 6rem;
}

.cart-desc {
    display: flex;
    flex-direction: row;
    width: 80%;
    gap: 1rem;
}

.cart-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: .3rem;
}

.cart-input {
    display: flex;
    width: 15%;
    justify-content: right;
}

.cart-details span {
    cursor: pointer;
}
.faq-container {
    justify-content: center;
    padding: 5rem;
    flex: 1;
    margin-bottom: auto;
}

.faq-content {
    max-width: 48rem;
}

.faq-title {
    align-items: center;
    text-align: center;
    font-size: 4.125rem;
    line-height: 1.2;
    font-weight: 600;
}

.gap {
    padding: 4rem;
}

.accordion-button:not(.collapsed) {
    color: var(--primary-color);
}
.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
}


@media only screen and (max-width: 760px) {
    .faq-container {
        padding: .5rem;
    }

}